<template>
  <div class="card">
    <div class="card-body">
      <table class="table  table-striped bg-white">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(role, i) in roles" :key="`role-${i}`">
            <td>{{ i + 1 }}</td>
            <td>{{ role.name }}</td>
            <td>
              <router-link
                :to="{ name: 'roles.show', params: { id: role.id } }"
                class="btn btn-sm btn-info"
                ><i class="lni-eye mr-2"></i>Details</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      roles: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios.get("/api/v1/dashboard/roles").then((response) => {
        this.roles = response.data.roles;
        setTimeout(() => {
          this.$loader.stop();
          $("table").DataTable();
        }, 1000);
      });
    },
  },
};
</script>
